<!--
  简单自定义表单
  @author GongLiHai
-->
<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="show" @close="cancel">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="validatorRules">
      <a-form-model-item v-for="(formItem, index) in config.item" :label="formItem.name" :prop="formItem.field">
        <!-- 富文本 richText -->
        <editor v-if="formItem.type == 'richText'" :placeholder='getRequiredCheckFailMessage(formItem)'
          v-model="form[formItem.field]" />

        <!-- 文本域 textarea -->
        <a-textarea v-else-if="formItem.type == 'textarea'" :rows='4' :placeholder='getRequiredCheckFailMessage(formItem)'
          v-model="form[formItem.field]" />

        <!-- 单选框 radio -->
        <a-radio-group v-else-if="formItem.type == 'radio'" v-model="form[formItem.field]">
          <template v-if="formItem.option">
            <a-radio v-for="option in formItem.option" :value="option.value">{{ option.name }}</a-radio>
          </template>
          <template v-else>
            <a-radio :value='0'>否</a-radio>
            <a-radio :value='1'>是</a-radio>
          </template>
        </a-radio-group>

        <!-- 开关 -->
        <switch-pack v-else-if="formItem.type == 'switch'" v-model="form[formItem.field]"></switch-pack>

        <!-- 文件, 图片 -->
        <a-upload v-else-if="['video', 'img', 'file'].includes(formItem.type)" listType='picture-card'
          class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => fileBeforeUpload(formItem, info)'
          :customRequest='(info) => fileUploadRequest(formItem, info)' v-model="form[formItem.field]">
          <template v-if="!uploadLoad[formItem.field] && form[formItem.field]">
            <!-- 视频, 显示视频 -->
            <video v-if="formItem.type == 'video'" :src='form[formItem.field]' :alt='formItem.name'
              style='height:104px;max-width:300px' />
            <!-- 图片, 显示图片 -->
            <img v-else-if="formItem.type == 'img'" :src='form[formItem.field]' :alt='formItem.name'
              style='height:104px;max-width:300px' />
            <!-- 文件, 显示文件名 -->
            <div v-else>{{ form[formItem.field] }}</div>
          </template>
          <div v-else>
            <a-icon :type="uploadLoad[formItem.field] ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ uploadLoad[formItem.field] ? '上传中' : '上传' + formItem.name }}</div>
          </div>
        </a-upload>

        <!-- 多图 -->
        <multi-img-upload v-else-if="formItem.type == 'imgs'" v-model="form[formItem.field]"
          :maxCount="formItem.maxCount"></multi-img-upload>

        <!-- 日期时间选择器 -->
        <a-date-picker v-else-if="formItem.type == 'datetime'" showTime format='YYYY-MM-DD HH:mm:ss'
          valueFormat="YYYY-MM-DD HH:mm:ss" v-model="form[formItem.field]" />

        <!-- 数字输入框 -->
        <a-input-number v-else-if="formItem.type == 'number'" v-model="form[formItem.field]" :min="formItem.min"
          :max="formItem.max" :step="formItem.step" />

        <!-- 下拉框 -->
        <api-select :ref="'formItemRef_' + index" v-else-if="formItem.type == 'select'" :api="formItem.api"
          :placeholder='getRequiredCheckFailMessage(formItem)' v-model="form[formItem.field]" :option="formItem.option"
          :nameMapping="formItem.nameMapping" :valueMapping="formItem.valueMapping" />


        <!-- 输入框 input -->
        <a-input v-else :placeholder='getRequiredCheckFailMessage(formItem)' v-model="form[formItem.field]" allow-clear />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import request from "@/utils/request.js";
import Editor from '@/components/Editor'

import SwitchPack from './SwitchPack.vue'
import MultiImgUpload from './MultiImgUpload.vue'
import ApiSelect from './ApiSelect.vue';
import Util from "../Util";
import VideoPlay from '@/components/crud/VideoPlay'
import { Log } from "../Log.js";
import UploadFileToOSS from '@/utils/upload-file-to-oss.js'

export default {
  name: 'CreateForm',
  props: {
    config: Object
  },
  components: {
    Editor, SwitchPack, MultiImgUpload, ApiSelect
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {},
      show: false,
    }
  },
  computed: {
    /**
     * 表单校验规则
     */
    validatorRules() {
      const r = {};
      this.config.item.forEach((formItem) => {
        const rules = [];
        r[formItem.field] = rules;

        // 必填
        if (formItem.required) {
          rules.push({
            required: true,
            message: this.getRequiredCheckFailMessage(formItem),
            trigger: 'blur'
          });
        }
      });
      return r;
    }
  },
  created() {
    this.createForm();
    this.createFileUploadLoadState();
  },
  methods: {
    /**
     * 创建表单绑定值
     */
    createForm() {
      this.config.item.forEach(formItem => {
        this.$set(this.form, formItem.field, Util.functionDefaultValue(formItem.default, null));
      })
    },
    /**
     * 创建 上传组件使用的load状态 存储对象
     */
    createFileUploadLoadState() {
      const r = {};
      this.config.item.forEach(formItem => {
        if (['img', 'file'].includes(formItem.type)) {
          r[formItem.field] = false;
        }
      });
      this.uploadLoad = r;
    },
    /**
     * 获取必填项校验失败提示消息
     * 部分组件的 placeholder 也使用此方法
     * @param {config.form.item} formItem 表单项
     */
    getRequiredCheckFailMessage(formItem) {
      switch (formItem.type) {
        case "img":
        case "file":
        case "imgs":
          return `请上传${formItem.name}`;
        case "select":
          return `请选择${formItem.name}`;
        case "input":
        case "textarea":
        case "richText":
        default:
          return `请输入${formItem.name}`;
      }
    },
    /**
     * 下拉框再打开时刷新
     */
    reloadSelectData() {
      this.config.item.forEach((formItem, index) => {
        if (formItem.reload) {
          this.$refs['formItemRef_' + index][0].rqeuestOptions();
        }
      })
    },
    /**
     * 获取默认值对象
     */
    getDefaultData() {
      const r = {};
      this.config.item.forEach(item => {
        r[item.field] = Util.functionDefaultValue(item.default, null);
      });
      return r;
    },
    /**
     * 外部方法, 编辑
     * @param {*} data 表单数据
     */
    edit(data) {
      this.open(data, '编辑');
    },
    /**
     * 外部方法, 新增
     */
    add() {
      this.open({}, '新增')
    },
    /**
     * 打开表单
     * @param {*} data 表单数据
     * @param {*} title  标题
     */
    open(data, title) {
      this.form = Object.assign(this.getDefaultData(), data);
      this.$nextTick(() => {
        this.reloadSelectData();
        const formFields = [];
        this.config.item.forEach(item => {
          formFields.push(item.field);
        });
        this.show = true;
        this.formTitle = title;
      });
    },
    // 取消按钮
    cancel() {
      this.show = false
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /**
     * 提交按钮
     */
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          const isEdit = this.form.id !== undefined && this.form.id !== null;

          Log.info('表单保存', this.form);
          this.saveRequest().then(response => {
            this.$message.success(
              isEdit ? '修改成功' : '新增成功',
              3
            )
            this.show = false
            this.$emit('submit')
          }).finally(() => {
            this.submitLoading = false;
          })
        } else {
          return false
        }
      })
    },
    /**
     * 保存请求
     */
    saveRequest() {
      if (this.form.id !== undefined && this.form.id !== null) {
        return request({
          url: this.config.editApi,
          method: 'post',
          data: this.form
        })
      } else {
        return request({
          url: this.config.addApi,
          method: 'post',
          data: this.form
        })
      }
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(formItem, fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (formItem.type == 'img' && !fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(formItem, fileInfo) {
      Log.info('uploadfileInfo', fileInfo);

      this.uploadLoad[formItem.field] = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res);
          this.uploadLoad[formItem.field] = false;
          this.form[formItem.field] = res[0].url;
        },
      });
    },
  }
}
</script>
